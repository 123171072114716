<template>
  <div id="searchBar">
    <!-- search Input -->
    <CommonSearchInput
      v-if="isShowSearchInput"
      v-model="searchInput"
      @searchFn="searchFn"
      @keyup.enter="searchSubmit"
    />
    <!-- selection Multiple-->
    <CommonMultipleSelect
      v-if="isShowMultipleSelect"
      :label="selectLabel"
      :multiple-select="multipleSelect"
      title="name"
      item-value="id"
      class="me-3"
      @selectFn="selectFn"
    />
    <!-- search Btn -->
    <CommonButton
      :name="t('common.search')"
      variant="outlined"
      style="height:40px;"
      @keyup.enter="searchSubmit()"
      @click="searchSubmit()"
    />
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const props = defineProps({
  selectLabel: {
    type: String,
    default: ''
  },
  multipleSelect: {
    type: Array,
    default: () => []
  },
  isShowSearchInput: {
    type: Boolean,
    default: false
  },
  isShowMultipleSelect: {
    type: Boolean,
    default: false
  },
  clearSearchInputFn: {
    type: Boolean
  }
})

watch(() => props.clearSearchInputFn, (val) => {
  if (val) {
    clearSearchInput()
  }
})

const searchInput = ref('')
const searchFn = (v) => {
  searchInput.value = v
}

const clearSearchInput = () => {
  searchInput.value = ''
}

const selectItems = ref('')
const selectFn = (v) => {
  selectItems.value = v
}

const emit = defineEmits(['searchSubmitFn'])
const searchSubmit = () => {
  const selectValues = Object.values(selectItems.value).join(',')
  emit('searchSubmitFn', {
    searchInput: searchInput.value,
    select: selectValues
  })
}
</script>

<style lang="scss" scoped>
  #searchBar {
    display: flex;
    align-items: center;
  }
  #multipleSelect {
    height: 42px;

    :deep(.multipleLabel) {
      padding-right: 11px;
    }
  }
</style>>
